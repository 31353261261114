// online 配置文件
export const config = {

  BASE_URL: 'https://api-user-holilandpet.1900m.com',

  P1_IMG_DOMAIN: "https://p1-static-holilandpet.1900m.com/",

  XCX_USER_NAME: 'gh_d89070687955',
  XCX_PATH: '/pages/home/index.html', 
  SHARE_IMG_URL: 'https://p1-static-holilandpet.1900m.com/website_fe/act_wxshare.png',
  
  // 走api-wx
  H5_WX_LOGIN_PATH: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx856ec9c9a7ca3119&redirect_uri=${encodeURIComponent(
    'https://api-wx-holiland.1900m.com/web-api/weChat/getToken?prod=petwx'
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
}